import React from 'react';

import AuthGuard, { AuthGuardProps } from 'src/components/AuthGuard/AuthGuard';
import { useUserStore } from 'src/services/auth/auth';

export enum Permission {
  DAY_LABOR_EXPORT_DAYLABOR = 'day_labor.export_daylabor',
  DAY_LABOR_VIEW_DAYLABOR = 'day_labor.view_daylabor',
  DAY_LABOR_VIEW_TEAMDAYLABOR = 'day_labor.view_teamdaylabor',
  DAY_LABOR_CHANGE_TEAMDAYLABOR = 'day_labor.change_teamdaylabor',
  CUSTOMERS_VIEW_CUSTOMER = 'customers.view_customer',
  CUSTOMERS_ADD_CUSTOMER = 'customers.add_customer',
  CUSTOMERS_CHANGE_CUSTOMER = 'customers.change_customer',
  CUSTOMERS_DELETE_CUSTOMER = 'customers.delete_customer',
  USERS_ADD_USER = 'users.add_user',
  USERS_CHANGE_USER = 'users.change_user',
  USERS_DELETE_USER = 'users.delete_user',
  USERS_LOGIN_WEB = 'users.login_web',
  CONTRACTORS_VIEW_USER_STATUS = 'contractors.view_contractoruserstatus',
  CONTRACTORS_VIEW_PRICES = 'contractors.view_prices',
  CONTRACTORS_VIEW_ESTIMATION = 'contractors.view_estimation',
  CONTRACTORS_ADD_CONTRACTOR_PROJECT = 'contractors.add_contractorproject',
  CONTRACTORS_CHANGE_CONTRACTOR_PROJECT = 'contractors.change_contractorproject',
  FILES_DELETE_FILES = 'files.delete_file',
  FILES_CHANGE_FILES = 'files.change_file',
  TIME_TRACKING_VIEW_CHECKIN = 'time_tracking.view_checkin',
  TIME_TRACKING_VIEW_CHECKOUT = 'time_tracking.view_checkout',
  TIME_TRACKING_VIEW_TIMEBOOKING = 'time_tracking.view_timebooking',
  TIME_TRACKING_CHANGE_TIMEBOOKING = 'time_tracking.change_timebooking',
  TIME_TRACKING_CHANGE_TIMEBOOKING_CREATED_BY_ME = 'time_tracking.change_timebooking_created_by_me',
  TIME_TRACKING_ADD_TIMEBOOKING = 'time_tracking.add_timebooking',
  TIME_TRACKING_ADD_TEAMTIMEBOOKING = 'time_tracking.add_teamtimebooking',
  TIME_TRACKING_DELETE_TIMEBOOKING = 'time_tracking.delete_timebooking',
  TIME_TRACKING_DELETE_TIMEBOOKING_CREATED_BY_ME = 'time_tracking.delete_timebooking_created_by_me',
  TIME_TRACKING_EXPORT_TIMEBOOKINGS = 'time_tracking.export_time_bookings',
  SPECIFICATIONS_IMPORT_GAEB_FILES = 'specifications.import_gaeb_files',
  SPECIFICATIONS_DELETE_SPECIFICATION = 'specifications.delete_specification',
  SPECIFICATIONS_CHANGE_TASK = 'specifications.change_task',
  NOTES_VIEW_PROJECTNOTE = 'notes.view_projectnote',
  NOTES_ADD_PROJECTNOTE = 'notes.add_projectnote',
  NOTES_CHANGE_PROJECTNOTE = 'notes.change_projectnote',
  NOTES_DELETE_PROJECTNOTE = 'notes.delete_projectnote',
  REPORTS_VIEW_REPORT = 'reports.view_report',
  REPORTS_ADD_REPORT = 'reports.add_report',
  REPORTS_DELETE_REPORT = 'reports.delete_report',
  LEGAL_ENTITIES_VIEW_LEGAL_ENTITY = 'legal_entities.view_legalentity',
  LEGAL_ENTITIES_CHANGE_LEGAL_ENTITY = 'legal_entities.change_legalentity',
}

// Use this hook inside components to make checks about permissions
export const usePermissions = () => {
  const { user } = useUserStore();

  const hasPermission = (permission: Permission) => {
    return user.permissions?.includes(permission);
  };

  const hasPermissions = (permissions: Permission[]) => {
    return permissions?.every((permission) => user.permissions.includes(permission));
  };

  const hasOneOfPermissions = (permissions: Permission[]) => {
    return permissions?.some((permission) => user.permissions.includes(permission));
  };

  return {
    hasPermission,
    hasPermissions,
    hasOneOfPermissions,
  };
};

export const authGuardWithPermissions = (permissions: Permission[]) => {
  return (props: AuthGuardProps) => <AuthGuard permissions={permissions} {...props} />;
};
