import { User } from './user';

export interface ProgressCompoundValues {
  /**
   * Price based on the current progress of the related tasks.
   *
   * Requires the ``CONTRACTORS_VIEW_PRICES`` permission.
   */
  currentPrice?: number;

  /**
   * The total price of the related tasks.
   *
   * Requires the ``CONTRACTORS_VIEW_PRICES`` permission.
   */
  totalPrice?: number;

  /**
   * The price progress of the tasks.
   *
   * This is based on the price of the tasks, thus always 0, if there are no prices for a specification.
   */
  priceProgress: number;

  /**
   * Amount of work in minutes spent.
   * This is based on the minute value and current amount of all related tasks.
   *
   * Requires the ``CONTRACTORS_VIEW_ESTIMATION`` permission.
   *
   * This is a protected value, i.e. it will only ever be visible to the owner of the entity.
   */
  spentMinutes?: number;

  /**
   * The estimated amount of work in minutes.
   * This is based on the minute value and target amount of all related tasks.
   *
   * Requires the ``CONTRACTORS_VIEW_ESTIMATION`` permission.
   *
   * This is a protected value, i.e. it will only ever be visible to the owner of the entity.
   */
  estimatedMinutes?: number;

  /**
   * The minute progress of the tasks.
   *
   * This is based on the minute values of the tasks, thus always 0, if there are no minute values set.
   *
   * This is a protected value, i.e. it will only ever be visible to the owner of the entity.
   */
  minuteProgress?: number;
}

export interface Task {
  id: number;
  cardinalNumber: string;
  contingencyType: string | null;
  currentAmount: number;
  minuteValue: number;
  description: string;
  shortDescription: string;
  targetAmount: number;
  unit: string;
  remarkTextsCount: number;
  /**
   * The total price of the task. Calculated based on ``unitPrice`` * ``targetAmount``.
   *
   * Requires the ``CONTRACTORS_VIEW_PRICES`` permission.
   */
  totalPrice?: number;

  /**
   * The price one unit (amount) of the task costs.
   *
   * Requires the ``CONTRACTORS_VIEW_PRICES`` permission.
   */
  unitPrice?: number;
  currencySymbol: string;
  createdAt: string;
  modifiedAt: string;
}

export interface TaskList extends ProgressCompoundValues {
  cardinalNumberPart: string;
  currencySymbol?: string;
  id: number;
  parentTaskListId: number | null;
  specificationId: number | null;
  title: string | '';
  remarkTextsCount: number;
}

export interface TaskListMetaData extends Pick<TaskList, 'id' | 'specificationId'> {}

export interface TaskDetail extends Task {
  isLump: boolean;
  isUnbound: boolean;
  specifications: number[];
  taskLists: number[];
}

export interface TaskUpdatePayload {
  minuteValue: number;
}

export interface TaskProgress {
  id: number;
  amount: number;
  comment: string;
  recordedAt: string;
  task: number;
  user: User;
  createdAt: string;
  createdBy?: User;
  uuid: string;
}

export interface Specification extends ProgressCompoundValues {
  contractorProjectId?: number;
  contractorProjectName?: string;
  currencySymbol?: string;
  depth: number;
  id: number;
  title: string | '';
  createdAt: string;
  preambleCount: number;
  postscriptCount: number;
}

export enum AdditionalTextCategory {
  PROJECT_INFO = 'projectInfo',
  OWN = 'customerInfo',
  REQUESTER = 'consumerInfo',
  CONTRACTOR_INFO = 'bidderContractorInfo',
  CONSTRUCTION_SITE_INFO = 'constructionSiteInfo',
  NOTIFICATION_SITE_INFO = 'notificationSiteInfo',
  PREAMBLE = 'preamble',
  POSTSCRIPT = 'postscript',
}

export interface AdditionalText {
  id: number;
  category: AdditionalTextCategory;
  description: string;
  shortDescription: string;
  order: number;
  createdAt: string;
}

export interface RemarkText {
  id: number;
  taskList?: number;
  task?: number;
  order: number;
  description: string;
  shortDescription: string;
}

export interface SpecificationMetaData extends Pick<Specification, 'depth' | 'id' | 'title'> {}
